export const configurableData = {
    // Sign In 
    "logo": require("../assets/icons/logo1.png"),
    "logo_login": require("../assets/images/logo-login.png"),
    "sign_in_background_image": require("../assets/images/Background.jpg"),
    "sign_in_model": require("../assets/images/Model.png"),
    "sign_in_left_model": require("../assets/images/football-login-left.png"),
    "default_selected_category": "slots",

    // Lobby
    "lobby_background_image": require("../assets/images/background.gif"),

    // Header
    "available_paymentgateway_providers": "telcopay",
    "deposit_telcopay_image": require("../assets/images/coin.png"),

    // Categories
    "category_tab_1_type": "casino",
    "category_tab_1_name": "Live",
    "category_tab_1_icon": require("../assets/images/casino.png"),
    "category_tab_2_type": "slots",
    "category_tab_2_name": "Slots",
    "category_tab_2_icon": require("../assets/images/slot.png"),
    "category_tab_3_type": "evo888h5",
    "category_tab_3_name": "evo888h5",
    "category_tab_3_icon": require("../assets/images/evo888h5hot.png"),
    // "category_tab_4_type": "soccer",
    // "category_tab_4_name": "EUROCUP",
    // "category_tab_4_icon": require("../assets/images/soccer1.png"),
    // Currency 
    "currency_symbol": "MYR",
}

export const configurableDataBND = {
    // Sign In 
    "logo": require("../assets/icons/logo1.png"),
    "logo_login": require("../assets/images/logo-login.png"),
    "sign_in_background_image": require("../assets/images/Background.jpg"),
    "sign_in_model": require("../assets/images/Model.png"),
    "sign_in_left_model": require("../assets/images/football-login-left.png"),
    "default_selected_category": "slots",

    // Lobby
    "lobby_background_image": require("../assets/images/background.gif"),

    // Header
    "available_paymentgateway_providers": "",
    "deposit_telcopay_image": require("../assets/images/coin.png"),

    // Categories
    "category_tab_1_type": "casino",
    "category_tab_1_name": "Live",
    "category_tab_1_icon": require("../assets/images/casino.png"),
    "category_tab_2_type": "slots",
    "category_tab_2_name": "Slots",
    "category_tab_2_icon": require("../assets/images/slot.png"),
    "category_tab_3_type": "evo888h5",
    "category_tab_3_name": "evo888h5",
    "category_tab_3_icon": require("../assets/images/evo888h5hot.png"),
    // "category_tab_4_type": "evo888h5",
    // "category_tab_4_name": "evo888h5",
    // "category_tab_4_icon": require("../assets/images/evo888h5hot.png"),

    // Currency 
    "currency_symbol": "B$",
}

export const configurableDataThai = {
    "logo": require("../assets/images/th/logo2.png"),
    "logo_login": require("../assets/images/logo-login.png"),
    "sign_in_background_image": require("../assets/images/th/lobby_background.png"),
    "sign_in_model": require("../assets/images/th/luckyneko.png"),
    "sign_in_left_model": require("../assets/images/football-login-left.png"),
    "default_selected_category": "pragmatic",

    // Lobby
    "lobby_background_image": require("../assets/images/th/lobby_background.png"),

    // Header
    "available_paymentgateway_providers": "",
    "deposit_telcopay_image": require("../assets/images/coin.png"),
    
    // Categories
    "category_tab_1_type": "biggaming",
    "category_tab_1_name": "",
    "category_tab_1_icon": require("../assets/images/th/SAGAMING.png"),
    "category_tab_2_type": "pragmatic",
    "category_tab_2_name": "Pragmatic",
    "category_tab_2_icon": require("../assets/images/th/PGSOFT.png"),
    "category_tab_3_type": "",
    "category_tab_3_name": "",
    "category_tab_3_icon": "",

    // Currency 
    "currency_symbol": "฿",
}