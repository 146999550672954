import React, { useState } from "react";
function ImageWithLoader({
  src,
  onClick,
  ezSelect,
  selectedGame,
  game,
  StopProduct,
  handleProduct,
  isInAppGames
}) {
  const [loading, setLoading] = useState(true);
  return (
    <>
      <img
        onLoad={() => setLoading(false)}
        style={{
          maxWidth: "none",
          display: !loading ? "inline-block" : "none",
        }}
        className={`${isInAppGames ? "inapp-games-img" : "game-img"}`}
        src={game?.image_mobile}
        alt=""
        // onClick={() => startGameHandler(game)}
        onClick={() => {
          if (game.id || game.gameid) {
            if (selectedGame === game.id) {
              StopProduct(game.id);
            } else if (ezSelect && selectedGame === game.gameid) {
              StopProduct(game.gameid);
            } else {
              handleProduct(game);
            }
          }
        }}
      />
      {loading ? (
        <div
          role="status"
          className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center"
          style={{height: "50%", minWidth: "16.4vw", display:"flex", width:"100%"}}
        >
          <div className="flex items-center justify-center w-full h-full bg-gray-300 rounded dark:bg-gray-700">
            <svg
              className="w-10 h-10 text-gray-200 dark:text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
            </svg>
          </div>
        </div>
      ) : // <div>
      //   <div style={{height: "50%", minWidth: "16.4vw", display:"flex", width:"100%", background:"#ebebeb"}}></div>
      //   <div>
      //     <span></span>
      //   </div>
      // </div>
      null}
    </>
  );
}
export default ImageWithLoader;
